@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    /* justify-content: space-between; */
  }
  @media(max-width:767px){
    margin-top:0;
  }
}

.email {
  &>label {
    font-family: var(--fontFamilyFactor) !important;
    font-weight: 500;
  }

  &>input {
    background-color: #F9F9F9;
    font-family: var(--fontFamilyFactor) !important;
    font-weight: 500;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    height: 48px;
    margin-top:  7px;

    &::placeholder {
      color: #BDBDBD;
    }
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 9px !important;

  &>label {
    font-family: var(--fontFamilyFactor) !important;
    font-weight: 500;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyFactor) !important;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    height: 48px;
    color: #000;
    margin-top: 7px;

    &::placeholder {
      color: #BDBDBD;

    }
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 42px !important;

  & button {
    background-color: #000 !important;
    border-radius: 10px;
    color: #fff !important;

    &:hover,
    &:focus,
    &:active {
      background-color: #fff !important;
      color: #000 !important;
      &>svg {
        stroke: #000 !important;
      }
    }

    &>svg {
      stroke: #fff !important;
    }
  }
}

.errorMessage {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 12px;
  padding: 0px !important;
  margin: 0px !important;
  color: var(--colorFail);
  line-height: 13px;
  margin-bottom: 20px !important;
}