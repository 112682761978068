.cardContainer {
  display: flex;
  width: 100%;
  gap: 20px;
}
.searchLink {
  width: 100%;
  margin-top: 25px;
  object-fit: contain;
  /* padding: 0 15px 0 0; */
  min-width: auto;
  /* padding-right:15px; */
  cursor:pointer;
  &:hover {
    text-decoration: none;
  }
  /* background: #fff;
  padding: 15px 15px 30px;
  border-radius: 10px;
  margin: 15px 5px; */
  /* @media(min-width:1200px) and (max-width:1300px){
    min-width: 278px;
  }
  @media(min-width:1400px){
    min-width: 339px;
  }
  @media(min-width:1600px){
    min-width: 380px;
  }
  @media(min-width:1920px){
    min-width: 339px;
  } */
}

.imageContainer {
  position: relative;
  /* display: inline-block; */
  width: 350px;
  height: 415px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    max-width: 100%;
    border-radius: 10px;
    background-size: cover;
  }
}
.textOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  color: white;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:nth-child(1) {
    margin-left: auto;
    margin-top: 0;

    & > span:nth-child(1) {
      font-size: 16px;
      font-family:var(--fontFamilyHelvetica);
      font-weight:400;
      line-height:20px;
      text-align: right;
      @media(max-width:991px){
        font-size:14px;
        line-height:16px;
}
    }
    & > span:nth-child(2) {
      font-size: 12px;
      font-family:var(--fontFamilyHelvetica);
      font-weight:400;
      line-height:16px;
     @media(max-width:991px){
      font-size: 10px;
      line-height:14px;
     }
    }
  }
  & > div:nth-child(2) {
    margin-right: auto;
    margin-top: auto;
    & > span:nth-child(1) {
      font-size: 20px;
      line-height:25px;
      font-weight:500;
      font-family:var(--fontFamilyHelvetica);
      @media(max-width:991px){
        font-size:16px;
        line-height:20px;
      }
    }
    & > span:nth-child(2) {
      font-size: 12px;
      line-height:16px;
      font-weight:400;
      font-family:var(--fontFamilyHelvetica);
    }
  }
  @media(max-width:575px){
    padding:15px;
  }
}
.previewImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.loader {
  width: 96px;
  height: 96px;
  margin: 0 auto;
}
.muteButton{
  position: absolute;
  bottom:10px;
  right:20px;
  color:white;
  cursor:pointer;
  z-index:1;
  &>svg{
    width:25px;
    height:25px;
    /* & > path{
      fill:#fff;
      stroke:#fff;
    } */
  }
}
.noneOnHover{
    display:none !important;
  }

.imageDiv{
  width:100%;
  height:100%;
}
.loaded{
  display:none;
}