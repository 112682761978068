@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    /* justify-content: space-between; */
  }
}

.name {
  gap: 40px;

  @media(min-width:768px) {
    display: flex;
    justify-content: space-between;

  }

  /* margin-top: 24px; */

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}

.firstAndLastNameRootContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.firstAndLastNameRoot {
  @media(min-width:768px) {
    width: 100%;
    flex: 100%;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000;
  }
}

.firstNameRoot {
  @media(min-width:768px) {
    width: 50%;
    flex: 50%;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000;
  }
}

.lastNameRoot {
  @media(max-width:991px) {
    width: 50%;
    flex: 0 0 50%;
  }

  @media(max-width:767px) {
    margin-top: 12px;
    width: 100%;
    flex: 0 0 100%;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000;
  }
}

.email {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 12px;

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 20px !important;

  & button {
    font-weight: 600 !important;
    border-radius: 10px;
    font-weight: 500;
    font-family: var(--fontFamilyFactor);
    font-size: 14px;
    line-height: 16px;
    color: #F9F9F9 !important;
    background-color: #000 !important;

    &:hover {
      border: 1px solid black;
      color: #000 !important;
      background-color: #F9F9F9 !important;
    }
  }
}

.radioButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.errorMessage {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 12px;
  padding: 0px !important;
  margin: 0px !important;
  color: var(--colorFail);
  line-height: 13px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.locationFieldContainer {
  color: black;
  padding-top: 12px;
}