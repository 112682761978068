@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

html{
  height: 100%;
}

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */

.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* min-height:100vh; */
  /* margin-top: -66px; */

  @media (max-width: 991px) {
    /* max-width: 100vw; */
    /* min-height: 635px; */
    height: 100vh;
    min-height: auto;
  }
  @media(max-width:767px){
    background:linear-gradient(-45deg, #0000004d, #0009), url(../../assets/Homepage-Top-Banner-Mobile.jpg);
    background-position: 50% !important;
        background-size: cover !important;
  }
}

.confirmationContainer {
  display: flex;
  margin-top: -5rem;
  flex-direction: column;

  & div:nth-child(1) {
    font-size: x-large;
  }

  & div:nth-child(2) {
    font-size: larger;
  }

  & > div:nth-child(3) {
    margin-top: 20px;
  }

  & svg {
    width: 8rem;
  }

  @media (max-width: 767px) {
    align-items: center;
  }
}

.customModalContainer {
  width: 100%;
  height: 100vh;
  /* position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center; */
  /* background-color: red; */
}

.content {
  composes: marketplaceModalBaseStyles from global;
  border-radius: 5px;
  overflow: hidden;
  flex-basis: auto;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  margin: 0 !important;
  padding: 20px 0 !important;
  @media (--viewportMedium) {
    /* margin-top: 7.5vh;
    margin-bottom: 7.5vh; */
    min-height: 664px;
    min-width: 700px;
    /* padding: 0px !important; */
    border-bottom: none;
  }

  @media (max-width: 991px) {
    min-height: 530px;
  }

  @media (max-width: 767px) {
    height: 100vh;
    border-radius: inherit;
    padding: 29px 15px 15px;
    max-width: 100%;
  }
}

.joinOrSignParent {
  display: flex;
  min-height: 664px;
  background-color: #ffffff;
  position: relative;

  @media (max-width: 991px) {
    min-height: 530px;
  }

  @media (max-width: 767px) {
    min-height: 100vh;
  }
}

.joinOrSignImage {
  /* background-color: red; */
  /* width: 50%;
  flex: 0 0 50%; */
  width: 48%;
  flex: 48%;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.joinOrSignAuthentication,
.traineeContainer {
  width: 50%;
  flex: 50%;
  padding: 30px;
  position: relative;

  /* width:50%;
  flex:0 0 50%;
  padding:30px; */
  @media (max-width: 991px) {
    padding: 25px 15px;
  }

  @media (max-width: 767px) {
    padding: 0px;
  }

  /* display:flex;
  justify-content: center;
  align-items: center; */
}

.close {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #676767;
  display: none;
  /* width: max-content; */
}

.totalContent {
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: space-between;
  height: 100%; */
}

.joinNowContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 30px; */
  /* min-height: 378px; */
  /* margin-bottom: 120px; */
  /* min-height: 580px; */

  @media (max-width: 991px) {
    margin-bottom: 40px;
    min-height: 400px;
  }

  @media (max-width: 767px) {
    /* min-height: 85vh;
    margin-bottom: 20px; */
    height: 100%;
  }
}

.signupFormWrapper {
  height: 100%;
}

.signinHeader {
  /* margin-bottom: 10px; */
  /* font-family: 'Factor A'; */
  /* font-weight:1000; */
  font-family: var(--fontFamilyFactor);
  font-weight: 500;
  color: #000;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    display: none;
  }
}

.MobsigninHeader {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.customForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.heroContentTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > svg {
    width: 8rem;
  }

  & div {
    display: flex;
    gap: 12px;
    color: white;
    cursor: pointer;
    font-size: 13px;
  }
}

.heroContentContainer {
  padding: 0 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: violet; */
  @media (max-width: 767px) {
    min-height: unset !important;
    height: 100% !important;
    padding: 0 15px;
  }
}

.heroContent {
  flex-grow: 1;
  display: flex;
  align-items: center;
  /* background-color: red; */

  & a:hover {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    flex-grow: 0;
    margin-top: 200px;
    & a {
      position: static;
      bottom: 0;
    }
  }
}

.heroMainTitle {
  /* composes: marketplaceHeroTitleFontStyles from global; */
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  /* color: var(--matterColorLight); */
  & > span {
    font-size: 48px;
    font-weight: 500;
    line-height: 52px;
    color: #fff;
    margin-bottom: 0;
    font-family: 'Factor A';

    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: inherit;
    margin-bottom: 0;
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  /* composes: h4 from global; */

  /* color: var(--matterColorLight); */
  /* margin: 0 0 32px 0; */
  composes: animation;

  font-size: 48px;
  color: #fff;
  line-height: 52px;
  font-weight: 400;
  font-family: 'Factor A';
  font-style: Medium;
  margin: 20px 0 !important;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    /* margin: 0 0 47px 0; */
  }

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 30px;
    margin: 0 0 20px;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.signuplinkText {
  margin: auto 0 36px 0;
  background-color: #fff;
  padding: 12px 30px;
  border-radius: 100px;
  color: #000;
  font-size: 16px;
  text-align: center;
  min-width: 197px;
  font-family: 'Helvetica Neue';
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 55px;

  @media (max-width: 991px) {
    margin-left: 0;
    min-width: 163px;
    font-size: 12px;
    padding: 12px 0;
    width: fit-content;
  }
}

.passwordEntryFormHeader {
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    /* position: absolute; */
    display: flex;
    justify-content:flex-start;
  }

  & svg {
    width: 8rem;
  }
}

.passwordEntryFormFooter {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 10px;
  /* margin-bottom: 12px; */
  /* margin-top: 48px; */
  line-height: 14px;
  color: #676767;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  max-width: 420px;

  /* margin-top: 200px; */
  /* @media(max-width:767px){
    margin-top:170px;
  } */
  @media (max-width: 767px) {
    max-width: unset;
  }
}
.waitlistModal {
  & > div {
    & > div {
      flex-basis: 990px !important;
      @media (max-width: 991px) {
        flex-basis: auto !important;
        max-width: 700px !important;
        padding: 0 20px !important;
      }
      @media (max-width: 767px) {
        position: fixed !important;
        top: 0 !important;
        max-width: 100% !important;
        left: 0 !important;
        right: 0 !important;
      }
      & > div {
        & > div {
          height: 100% !important;
        }
        & > div {
          & > div {
            min-width: auto !important;
            @media(max-width:767px){
              height:100% !important;
            }
          }
        }
      }
    }
  }
}
