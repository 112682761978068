.contactUsdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div {
    flex: 0 0 48%;
    width: 48%;
    & > div{
      & > img {
        width: 100%;
        border-radius: 10px;
        max-height: 568px;
        object-fit: cover;
        @media (max-width: 767px) {
          min-height: 340px;
        }
      }
    }
   @media(max-width:767px){
    flex: 0 0 100%;
    width: 100%;
   }
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
.bottomDiv {
  & > h1 {
    font-weight: 500;
    font-size: 38px;
    /* line-height: 52.78px; */
    line-height: 42px;
    margin: 15px 0;

    color: #000;
    @media (max-width: 1199px) {
      font-size: 30px;
    }
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
  & > p {
    color: #000;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    margin-bottom: 10px;
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
.contactUsBtn {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 100px;
  font-size: 16px;
  cursor: pointer;
  margin: 15px 0;
  line-height: 20px;
  font-family: var(--fontFamilyHelvetica);
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 40px;
    margin-top: 13px;
  }
}
.ContactUsmodal {
  & > div {
    background-color: rgb(0, 0, 0, 0.9) !important;
    & > div {
      padding: 25px 15px !important;
      & > div {
        & > div {
          & > h1 {
            font-family: Factor A;
            font-size: 30px;
            font-weight: 500;
            line-height: 52px;
            margin-bottom: 0;
            color: #000;
          }
          & > p {
            color: #000;
            font-family: var(--fontFamilyFactor);
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
          }
        }
      }
    }
  }
}
.Img{
 & .deskImage{
  display: block;
  @media(max-width:767px){
   display: none;
  }
 }
 & .mobImage{
  display: none;
  @media(max-width:767px){
    display: block;
   }
 }
}