.slickContainer {
  /* max-width: 1400px; */
}
.headerDiv {
  display: flex;
  & > span {
    color: #000;
    font-size: 20px;

    font-weight: 500;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
  & > button {
    margin-left: auto;
    border: none;
    border-bottom: 1px solid #000;
    padding: 0;
    color: #000;
    font-size: 20px;
    font-family:var(--fontFamilyFactor);
    font-weight:500;
    cursor: pointer;
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
}
.sliderDiv {
  & > p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    /* width: 40%; */
    margin-top: 10px;
    max-width: 590px;
    font-family:var(--fontFamilyFactor);
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      margin-top:15px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 18px;
      width: 100%;
      margin-top:15px;
    }
  }
  /* max-width: 1202px;
  margin: 0 auto; */
  /* @media(min-width:1200px) and (max-width:1300px){
    max-width: 1180px;
    margin: 0 auto;
  } */
  /* @media(min-width:1400px){
    max-width: 1340px;
  } */

  /* @media(min-width:1600px){
    max-width: 1510px;
  
  } */
  /* @media(min-width:1700px){
    max-width: 1620px;
  
  } */
  /* @media(min-width:1920px){
    max-width: 1440px;
   
  } */
}
.communityFavourites {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    & > div {
      & > div:nth-child(2) {
        & > div {
          /* transform: translate3d(0px, 0px, 0px) !important; */
          & > div {
            min-width: 320px;
            @media (max-width: 991px) {
              min-width: 283px;
            }
          }
        }
      }
    }
  }

  & > div {
    & > svg {
      fill: none;
    }
    & > div{
    /* padding: 0 5px 0 15px;
    margin-left: -15px; */
    margin-right: -5px;
    padding: 0 15px;
    margin-left: -10px;
    & > div{
      & > div{
        & > div{
          & > div{
       
  
           @media(min-width:1200px) and (max-width:1299px){
            width: 100% !important;
           }

            @media(min-width:1300px){
              width: 290px !important;
            }
            @media(min-width:1360px){
              width: 310px !important;
            }

            @media(min-width:1400px) and (max-width:1499px){
              width: 320px !important;
            }
            @media(min-width:1500px) and (max-width:1599px){
              width: 344px !important;
            }
            @media(min-width:1600px) and (max-width:1699px){
              width: 360px !important;
            }

            @media(min-width:1700px){
              width: 390px !important;
            }
            @media(min-width:1800px){
              width: 420px !important;
            }
            @media(min-width:1920px){
              width: 330px !important;
            }

            @media(max-width:1199px){
              width: 100% !important;
            }
            
            /* @media(min-width:1024px) and (max-width:1070px){
              width: 225px !important;
            }
            @media(min-width:992px) and (max-width:1023px){
              width: 300px !important;
            } */
            /* @media(max-width:991px){
              width: 100% !important;
            } */

               
          }
        }
      }
    }
    @media(max-width:575px){
      margin-right: -10px;
     }
    }
  }
}
.communityFavourites{
    /* margin:0 auto;
    max-width: 1200px; */
    /* width: 100%; */
    /* @media(min-width:1600px){
      max-width:1440px;
    } */
}