.layoutWrapperMain {
  background-color: #fff;
}

.landingPageContent {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 35px 150px;

  @media(min-width:1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    /* max-width: 100vw; */
    margin: 0;
    padding: 0 25px 128px;
  }

  @media (max-width: 575px) {
    /* max-width: 100vw; */
    margin: 0;
    padding: 0 15px 80px;
  }
}

.aboutFleggsDiv,
.trianerQuoteDiv {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 50%;
  /* max-width: 740px; */
  background-color: #fff;
  padding: 80px 0;

  &>span {
    font-size: 20px;
    color: #000;
    /* line-height: 20px; */
    font-weight: 500;
    font-family: var(--fontFamilyFactor);

    @media (max-width: 991px) {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &>p {
    font-size: 38px;
    line-height: 52px;
    font-weight: 400;
    margin-top: 0;
    padding-top: 0;
    color: #000;
    font-family: var(--fontFamilyFactor) !important;

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    @media(max-width:767px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 50px 0;
  }

  @media (max-width: 575px) {
    /* width: 100%; */
    padding: 30px 0;
  }
}

.aboutFleggsDiv {
  &>span {
    margin-bottom: 10px;
  }
}

.trianerQuoteDiv {
  &>span {
    margin-top: 5px;
  }

  &>p {
    @media(max-width:991px) {
      font-weight: 400;
    }
  }
}

.startYourJourneyDiv {
  text-align: center;
  margin: 120px auto 0;

  &>h1 {
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
    color: #000;
    /* margin: 0; */
    margin-bottom: 15px;

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &>p {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin: 0 0 10px;

    @media (max-width: 991px) {
      font-size: 20px;
    }
  }

  @media(max-width:1199px) {
    margin: 100px auto 0;
  }

  @media (max-width: 991px) {
    margin: 80px auto 0;
    text-align: left;
  }
}

.startYourJourneyBtn {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
  font-family: var(--fontFamilyHelvetica);
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 12px;
  }
}

.LandingpagelayoutMain {
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}